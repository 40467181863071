import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import PrivacyNotice from "../components/privacy/privacyNotice"

const title = `Datenschutz`;

const PrivacyStatementPage = () => (
  <Layout title={title}>
    <SEO title={title} pathname="/datenschutz" />
    <PrivacyNotice />
  </Layout>
)

export default PrivacyStatementPage
