import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./privacyNotice.module.css"
import { navigate } from "gatsby"

export default function PrivacyNotice() {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(privacy/privacy.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: data.allMarkdownRemark.edges[0].node.html,
      }}
      className={styles.content}
    />
  )
}
